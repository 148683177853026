import React from 'react'
import { Script } from 'gatsby-script'
import { MailIcon, PhoneIcon, AtSymbolIcon } from '@heroicons/react/outline'


export default function Contact() {
  return (
    <div className="bg-gray-50">
      <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
      <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">Nous contacter</h2>
      <p className="mb-8 text-lg text-gray-500 lg:py-6">
      Pour nous contacter, nous vous invitons à remplir le formulaire ci-dessous.<br />
      Pour postuler à une offre d’emploi ou transmettre votre candidature spontanée, nous vous recommandons l’email contact<AtSymbolIcon  className="h-5 inline" />ceosavocats.com
      </p>
        <div className="relative bg-gray-100 shadow-xl rounded-md">
          
       

          <div className="grid grid-cols-1 lg:grid-cols-3">
            {/* Contact information */}
            <div className="relative overflow-hidden py-10 px-6 bg-orange-600 sm:px-10 xl:p-12">
              <div className="absolute inset-0 pointer-events-none sm:hidden" aria-hidden="true">
                <svg
                  className="absolute inset-0 w-full h-full"
                  width={343}
                  height={388}
                  viewBox="0 0 343 388"
                  fill="none"
                  preserveAspectRatio="xMidYMid slice"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                    fill="url(#linear1)"
                    fillOpacity=".1"
                  />
                  <defs>
                    <linearGradient
                      id="linear1"
                      x1="254.553"
                      y1="107.554"
                      x2="961.66"
                      y2="814.66"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#fff" />
                      <stop offset={1} stopColor="#fff" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div
                className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none sm:block lg:hidden"
                aria-hidden="true"
              >
                <svg
                  className="absolute inset-0 w-full h-full"
                  width={359}
                  height={339}
                  viewBox="0 0 359 339"
                  fill="none"
                  preserveAspectRatio="xMidYMid slice"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
                    fill="url(#linear2)"
                    fillOpacity=".1"
                  />
                  <defs>
                    <linearGradient
                      id="linear2"
                      x1="192.553"
                      y1="28.553"
                      x2="899.66"
                      y2="735.66"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#fff" />
                      <stop offset={1} stopColor="#fff" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div
                className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none lg:block"
                aria-hidden="true"
              >
                <svg
                  className="absolute inset-0 w-full h-full"
                  width={160}
                  height={678}
                  viewBox="0 0 160 678"
                  fill="none"
                  preserveAspectRatio="xMidYMid slice"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                    fill="url(#linear3)"
                    fillOpacity=".1"
                  />
                  <defs>
                    <linearGradient
                      id="linear3"
                      x1="192.553"
                      y1="325.553"
                      x2="899.66"
                      y2="1032.66"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#fff" />
                      <stop offset={1} stopColor="#fff" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <h3 className="text-lg font-medium text-white">CÉOS Avocats (Lyon/Villeurbanne)</h3>
              <p className="mt-6 text-base text-orange-50 max-w-3xl">
              IMMEUBLE LE PALAIS D’HIVER<br />
              149 boulevard de Stalingrad<br />
              69100 Villeurbanne
              </p>
              <dl className="mt-8 space-y-6">
                <dt>
                  <span className="sr-only">Téléphone</span>
                </dt>
                <dd className="flex text-base text-orange-50">
                  <PhoneIcon className="flex-shrink-0 w-6 h-6 text-orange-200" aria-hidden="true" />
                  <span className="ml-3">04 86 80 10 47</span>
                </dd>
                <dt>
                  <span className="sr-only">Email</span>
                </dt>
                <dd className="flex text-base text-orange-50">
                  <MailIcon className="flex-shrink-0 w-6 h-6 text-orange-200" aria-hidden="true" />
                  <span className="ml-3">contact<AtSymbolIcon  className="h-5 inline" />ceosavocats.com</span>
                </dd>
                <dd className="flex text-base text-orange-50">
                  <a href="https://www.linkedin.com/company/selarl-ceosavocats/about/" target="_blank" rel="noreferrer" className="text-white hover:text-orange-200">
                    <span className="sr-only">LinkedIn</span>
                    <svg class="w-5 h-5 fill-current" role="img" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">
                      <g><path d="M218.123122,218.127392 L180.191928,218.127392 L180.191928,158.724263 C180.191928,144.559023 179.939053,126.323993 160.463756,126.323993 C140.707926,126.323993 137.685284,141.757585 137.685284,157.692986 L137.685284,218.123441 L99.7540894,218.123441 L99.7540894,95.9665207 L136.168036,95.9665207 L136.168036,112.660562 L136.677736,112.660562 C144.102746,99.9650027 157.908637,92.3824528 172.605689,92.9280076 C211.050535,92.9280076 218.138927,118.216023 218.138927,151.114151 L218.123122,218.127392 Z M56.9550587,79.2685282 C44.7981969,79.2707099 34.9413443,69.4171797 34.9391618,57.260052 C34.93698,45.1029244 44.7902948,35.2458562 56.9471566,35.2436736 C69.1040185,35.2414916 78.9608713,45.0950217 78.963054,57.2521493 C78.9641017,63.090208 76.6459976,68.6895714 72.5186979,72.8184433 C68.3913982,76.9473153 62.7929898,79.26748 56.9550587,79.2685282 M75.9206558,218.127392 L37.94995,218.127392 L37.94995,95.9665207 L75.9206558,95.9665207 L75.9206558,218.127392 Z M237.033403,0.0182577091 L18.8895249,0.0182577091 C8.57959469,-0.0980923971 0.124827038,8.16056231 -0.001,18.4706066 L-0.001,237.524091 C0.120519052,247.839103 8.57460631,256.105934 18.8895249,255.9977 L237.033403,255.9977 C247.368728,256.125818 255.855922,247.859464 255.999,237.524091 L255.999,18.4548016 C255.851624,8.12438979 247.363742,-0.133792868 237.033403,0.000790807055"></path></g>
                    </svg>
                  </a>
                </dd>
              </dl>
              <h3 className="mt-16 text-lg font-medium text-white">CÉOS Avocats (Nantes)</h3>
              <p className="mt-6 text-base text-orange-50 max-w-3xl">
              25 boulevard des Martyrs Nantais de la Résistance<br />
              44200 Nantes
              </p>
              <dl className="mt-8 space-y-6">
                <dt>
                  <span className="sr-only">Téléphone</span>
                </dt>
                <dd className="flex text-base text-orange-50">
                  <PhoneIcon className="flex-shrink-0 w-6 h-6 text-orange-200" aria-hidden="true" />
                  <span className="ml-3">02 51 88 99 25</span>
                </dd>
                <dt>
                  <span className="sr-only">Email</span>
                </dt>
                <dd className="flex text-base text-orange-50">
                  <MailIcon className="flex-shrink-0 w-6 h-6 text-orange-200" aria-hidden="true" />
                  <span className="ml-3">contact<AtSymbolIcon  className="h-5 inline" />ceosavocats.com</span>
                </dd>
                <dd className="flex text-base text-orange-50">
                  <a href="https://www.linkedin.com/company/selarl-ceosavocats/about/" target="_blank" rel="noreferrer" className="text-white hover:text-orange-200">
                    <span className="sr-only">LinkedIn</span>
                    <svg class="w-5 h-5 fill-current" role="img" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">
                      <g><path d="M218.123122,218.127392 L180.191928,218.127392 L180.191928,158.724263 C180.191928,144.559023 179.939053,126.323993 160.463756,126.323993 C140.707926,126.323993 137.685284,141.757585 137.685284,157.692986 L137.685284,218.123441 L99.7540894,218.123441 L99.7540894,95.9665207 L136.168036,95.9665207 L136.168036,112.660562 L136.677736,112.660562 C144.102746,99.9650027 157.908637,92.3824528 172.605689,92.9280076 C211.050535,92.9280076 218.138927,118.216023 218.138927,151.114151 L218.123122,218.127392 Z M56.9550587,79.2685282 C44.7981969,79.2707099 34.9413443,69.4171797 34.9391618,57.260052 C34.93698,45.1029244 44.7902948,35.2458562 56.9471566,35.2436736 C69.1040185,35.2414916 78.9608713,45.0950217 78.963054,57.2521493 C78.9641017,63.090208 76.6459976,68.6895714 72.5186979,72.8184433 C68.3913982,76.9473153 62.7929898,79.26748 56.9550587,79.2685282 M75.9206558,218.127392 L37.94995,218.127392 L37.94995,95.9665207 L75.9206558,95.9665207 L75.9206558,218.127392 Z M237.033403,0.0182577091 L18.8895249,0.0182577091 C8.57959469,-0.0980923971 0.124827038,8.16056231 -0.001,18.4706066 L-0.001,237.524091 C0.120519052,247.839103 8.57460631,256.105934 18.8895249,255.9977 L237.033403,255.9977 C247.368728,256.125818 255.855922,247.859464 255.999,237.524091 L255.999,18.4548016 C255.851624,8.12438979 247.363742,-0.133792868 237.033403,0.000790807055"></path></g>
                    </svg>
                  </a>
                </dd>
              </dl>
              
            </div>

            
            <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
              <h3 className="text-lg font-medium text-gray-900">Votre demande</h3>
              <form id="form-contact" action="https://app.koudepouce.fr/ceosavocats/contact/" method="POST" className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                <div>
                  <label htmlFor="prenom" className="block text-sm font-medium text-gray-900">
                    Prénom
                  </label>
                  <div className="mt-1">
                    <input
                        required
                      type="text"
                      name="prenom"
                      id="prenom"
                      autoComplete="given-name"
                      className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-orange-500 focus:border-orange-500 border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="nom" className="block text-sm font-medium text-gray-900">
                    Nom
                  </label>
                  <div className="mt-1">
                    <input
                        required
                      type="text"
                      name="nom"
                      id="nom"
                      autoComplete="family-name"
                      className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-orange-500 focus:border-orange-500 border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-900">
                    Email
                  </label>
                  <div className="mt-1">
                    <input
                        required
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-orange-500 focus:border-orange-500 border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div>
                  <div className="flex justify-between">
                    <label htmlFor="telephone" className="block text-sm font-medium text-gray-900">
                      Téléphone
                    </label>
                  </div>
                  <div className="mt-1">
                    <input
                      required
                      type="text"
                      name="telephone"
                      id="telephone"
                      autoComplete="tel"
                      className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-orange-500 focus:border-orange-500 border-gray-300 rounded-md"
                      aria-describedby="phone-optional"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <div className="flex justify-between">
                    <label htmlFor="message" className="block text-sm font-medium text-gray-900">
                      Message
                    </label>
                  </div>
                  <div className="mt-1">
                    <textarea
                        required
                      id="message"
                      name="message"
                      rows={4}
                      className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-orange-500 focus:border-orange-500 border border-gray-300 rounded-md"
                      aria-describedby="message-max"
                      defaultValue={''}
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <div className="mt-1">
                    <input className="jCaptcha py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-orange-500 focus:border-orange-500 border-gray-300 rounded-md" type="text" placeholder="Résultat" required />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="message" className="sr-only">
                    Message
                  </label>
                  <input 
                      type="checkbox" 
                      id="cgu" 
                      name="cgu" 
                      className="inline mr-3 shadow-sm py-1 px-3 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md" 
                      required 
                  /> J'accepte les <a href="/legal/cgu/" className="text-orange-500" target="_blank" rel="noreferrer">Conditions Générales d'Utilisation</a>
                </div>
                <div className="sm:col-span-2 sm:flex sm:justify-end">
                  <button
                    type="submit"
                    className="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:w-auto"
                  >
                    Envoyer
                  </button>
                </div>
              </form>


              <Script src="/jCaptcha.js" />

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
